import React, {useState} from 'react'
import ReactPlayer from 'react-player/file'
import {css} from "@emotion/react";

interface Props {
  url: string
}

const VideoPlayer: React.FC<Props> = ({url}) => {
  const [playing, setPlaying] = useState(false);
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setPlaying(!playing)
      }}
      role="button"
      css={css`
        position: relative;
        cursor: pointer;
      `}>
      <div css={css`
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        opacity: ${playing ? 0 : 1};
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity .3s ease;
      `}>
        <svg xmlns="http://www.w3.org/2000/svg" width="160" height="187" viewBox="0 0 160 187">
          <path id="Polygon_2" data-name="Polygon 2"
                d="M82.276,19.207a13,13,0,0,1,22.448,0L175.57,140.441A13,13,0,0,1,164.346,160H22.654A13,13,0,0,1,11.43,140.441Z"
                transform="translate(160) rotate(90)" fill="#fff" opacity="0.731"/>
        </svg>
      </div>
      <ReactPlayer
        volume={1}
        controls={false}
        url={url}
        width="unset"
        height="unset"
        playing={playing}
      >
      </ReactPlayer>
    </div>
  )
}

export default VideoPlayer;
