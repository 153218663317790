import React, {ReactNode} from 'react'
import {css, useTheme} from "@emotion/react";
import SplitSection from "../SplitSection";
import {mobileBreakpoint} from "../../constants";

interface Props {
  title: string,
  children: ReactNode
}

const InfoSection: React.FC<Props> = ({title, children}) => {
  const theme = useTheme();
  return (
    <div css={css`
      border-top: 2px solid ${theme.color.primary};
      @media (max-width: ${mobileBreakpoint}px) {
        border-top: 1px solid ${theme.color.primary};
      }
    `}>
      <SplitSection>
        <div css={css`
          padding: ${theme.margin.main}px;
          text-transform: uppercase;
          flex-shrink: 0;
          @media (max-width: ${mobileBreakpoint}px) {
            text-align: center;
          }
        `}>
          {title}
        </div>
        <div css={css`
          padding: ${theme.margin.main}px;
          text-transform: uppercase;
          color: ${theme.color.quaternary};
          flex-shrink: 0;
        `}>
          {children}
        </div>
      </SplitSection>
    </div>
  )
}

export default InfoSection;
