import * as React from "react"
import {useSiteMetadata} from "../../hooks/use-site-metadata";
import {ReactNode} from "react";

export interface SEOprops {
  title?: string,
  metaDescription?: string,
  children?: ReactNode
}

export const SEO = ({
                      title,
                      metaDescription,
                      children
                    }: SEOprops) => {
  const metaData = useSiteMetadata();
  const defaultTitle = metaData.title

  return (
    <>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
      <meta name="description" content={metaDescription || metaData.description}/>
      <meta property="og:title" content={title || defaultTitle}/>
      <meta property="og:description" content={metaDescription || metaData.description}/>
      <meta property="og:type" content="website"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:creator" content={metaData.author || ``}/>
      <meta name="twitter:title" content={title || defaultTitle}/>
      <meta name="twitter:description" content={metaDescription || metaData.description}/>
      {children}
    </>
  )
}
