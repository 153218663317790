import React, {ReactNode} from 'react'
import {css, useTheme} from "@emotion/react";
import SplitSection from "../SplitSection";
import VideoPlayer from "../VideoPlayer";

interface Props {
  children: ReactNode,
  videoURL: string
}

const InfoHead: React.FC<Props> = ({children, videoURL}) => {
  const theme = useTheme();
  return (
    <SplitSection>
      <div>
        <VideoPlayer
          url={videoURL}
        />
      </div>
      <div css={css`
        padding: ${theme.margin.main}px;
        text-transform: uppercase;
        color: ${theme.color.quaternary};
      `}>
        {children}
      </div>
    </SplitSection>
  )
}

export default InfoHead;
