import React from 'react'
import {css, useTheme} from "@emotion/react";


const FormattedText = ({content}: { content: string }) => {
  const theme = useTheme();
  return (
    <div css={css`
      white-space: pre-wrap;
    `}>
      {content}
    </div>
  )
}

export default FormattedText;
