import React, {ReactNode} from 'react'
import {css, useTheme} from "@emotion/react";
import {a} from "react-spring";
import {mobileBreakpoint} from "../../constants";

interface Props {

}

interface Props {
  href: string,
  children: ReactNode
}

const ContactLink: React.FC<Props> = ({href, children}) => {
  const theme = useTheme();
  return (
    <a
      target="_blank"
      href={href}
      css={css`
        color: ${theme.color.quaternary};
        text-decoration: none;
        transition: none;

        @media (max-width: ${mobileBreakpoint}px) {
          text-align: center;
          display: block;
          width: 100%;
          border: 1px solid ${theme.color.quaternary};
          padding: ${theme.margin.main * 2}px;
          margin-top: ${theme.margin.main}px;
        }
      `}
    >{children}</a>
  )
}

export default ContactLink;
